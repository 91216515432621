<template>
  <div class="category-title">
    <v-img
      v-if="headerImage && !$vuetify.breakpoint.xs"
      dark
      :src="headerImage"
      :height="height"
      class="elevation-0 rounded-0 d-flex align-center"
      @load="loaded = true"
    >
      <div class="layer"></div>
      <h1 class="text-center">
        {{ title }}
      </h1>
    </v-img>
    <div v-else class="d-flex align-center flex-row flex-wrap pb-5">
      <v-btn
        v-if="$platform_is_cordova && $routerHistory.hasPrevious()"
        elevation="0"
        outlined
        dark
        x-small
        :to="{ path: $routerHistory.previous().path }"
        class="back-button px-0"
        aria-label="Torna Indietro"
        ><v-icon medium elevation="0">mdi-chevron-left</v-icon></v-btn
      >
      <h1 class="pt-6 text-left" style="line-height: 35px;">
        {{ title }}
      </h1>
    </div>
  </div>
</template>
<script>
import get from "lodash/get";

export default {
  name: "PageTitle",
  props: {
    page: { type: Object, required: true },
    height: { type: String, default: "170px" }
  },
  computed: {
    title() {
      console.log(this.page);
      return get(this.page, "title.rendered");
    },
    headerImage() {
      return get(this.page, "featured_image_url");
    }
  }
};
</script>
