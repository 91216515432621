<template>
  <div class="faq faq-list-container">
    <v-container fluid class="relative">
      <page-title :page="page" />
      <v-row class="py-4">
        <v-col cols="0" md="3" v-if="!$vuetify.breakpoint.mobile">
          <h3 class="faq-title">FILTRA LE FAQ PER:</h3>
          <faq-category />
          <v-card
            class="faq-info-card d-flex flex-column justify-center align-center text-center pa-3"
            outlined
            flat
            tile
          >
            <v-icon size="50" color="primary">$info</v-icon>
            <v-card-title class="faq-info-card-title">
              <h3>Hai bisogno di ulteriore supporto?</h3>
            </v-card-title>
            <v-card-subtitle class="faq-info-card-subtitle">
              <h4>Basko è a tua disposizione!</h4>
            </v-card-subtitle>
            <v-card-actions class="faq-info-card-actions">
              <v-btn color="primary" :to="'/page/contatti'">
                Scopri il Servizio Clienti
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mobile ? '12' : '9'"
          class="position-relative"
        >
          <div v-if="faqData">
            <h2 class="faq-page-subtitle">
              Trovi di seguito alcune risposte alle domande che il Servizio
              Clienti riceve più frequentemente.
            </h2>
            <v-expansion-panels class="faq-list">
              <v-expansion-panel primary v-for="item in faqData" :key="item.id">
                <v-expansion-panel-header class="faq-panel-header">
                  <h4 v-html="item.title.rendered" @click="clicked"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-html="item.content.rendered"
                    @click="clicked"
                    class="faq-panel-content"
                  ></div>
                  <div>
                    <v-icon color="primary">$info</v-icon>
                    <span class="pl-2 primary--text font-weight-bold"
                      >La risposta non soddisfa la tua domanda?
                      <router-link
                        class="font-weight-black"
                        :to="'/page/contatti'"
                        >Chiedilo a noi</router-link
                      >
                    </span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="faq-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  $close
                </v-icon>
                <v-icon v-else>
                  $menu
                </v-icon>
              </v-btn>
            </template>
            <v-card><FaqCategory /> </v-card>
          </v-speed-dial>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.faq-page-subtitle {
  font-size: 1.5rem;
}
.faq-info-card {
  background: var(--v-green-lighten2) !important;
  .faq-info-card-title {
    color: var(--v-primary-darken1) !important;
    h3 {
      font-size: 1.3rem !important;
    }
  }
  .faq-info-card-subtitle {
    color: var(--v-primary-darken1) !important;
    h4 {
      font-size: 1rem !important;
    }
  }
}

.faq-list-container {
  .faq-title {
    font-size: 13px;
    color: $primary;
  }
  .position-relative {
    position: relative;
  }
  .faq-panel-header {
    // border: 1px var(--v-primary-base) solid;
    padding: 13px !important;
    background: var(--v-green-lighten2);
    h4 {
      font-size: 1rem;
      color: #000000;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 13px !important;
  }
  .faq-panel-content,
  .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: #000000 !important;
  }
  .faq-panel-content {
    margin-top: 16px;
  }
  .faq-list {
    .v-expansion-panel-header {
      color: var(--v-primary-base) !important;
      font-weight: bold;
    }
  }
  .faq-filter {
    .v-speed-dial__list {
      min-width: 400px;
      left: initial;
      right: 0;
    }
  }
}
</style>
<script>
import FaqCategory from "@/components/wordpress/FaqCategory.vue";
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";

import PageTitle from "@/components/wordpress/PageTitle.vue";
export default {
  name: "FaqList",
  components: { FaqCategory, PageTitle },
  data() {
    return {
      faqData: null,
      fab: false
    };
  },
  mixins: [page, clickHandler],
  mounted() {
    this.fetchFaqList(this.$route.params.categoryName);
    this.fetchPage("faq");
  },
  methods: {
    async fetchFaqList(categoryName) {
      let res = await CMSService.getCustomPostByFilters(
        "faq",
        [
          {
            filterName: "filter[categoria_faq]",
            filterValue: categoryName
          }
        ],
        100
      );

      this.faqData = res.data;
    }
  },
  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchFaqList(categoryName);
    }
  }
};
</script>
